<template>
    <div class="PaperAnalysis">
        <div class="table">
            <p>题型题量分析</p>
            <el-table :data="queStatVos" border style="width: 100%" size="mini" max-height="300px">
                <el-table-column prop="name" label="题型">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="题量"> </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>难度分析</p>
            <el-table :data="diffStatVos" border style="width: 100%" size="mini" max-height="300px">
                <el-table-column prop="name" label="试题难易度">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="题量"> </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>一致性分析</p>
            <el-table :data="conStatVos" border style="width: 100%" size="mini" max-height="300px">
                <el-table-column prop="name" label="试题一致性">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="题量"> </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>知识点分析</p>
            <el-table :data="knoStatVos" border style="width: 100%" size="mini" max-height="300px">
                <el-table-column prop="name" label="一级知识点">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="题量"> </el-table-column>
                <el-table-column prop="rate" label="占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table">
            <p>等级分析</p>
            <el-table :data="queLevelStatVos" border style="width: 100%" size="mini" max-height="300px">
                <el-table-column prop="name" label="试题等级">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="题量"> </el-table-column>
                <el-table-column prop="rate" label="题量占比">
                    <template slot-scope="scope">
                        {{ scope.row.rate }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaperAnalysis',
    data() {
        return {
            conStatVos: [],
            diffStatVos: [],
            knoStatVos: [],
            queLevelStatVos: [],
            queStatVos: [],
            difficultyName: ['', '容易', '较易', '一般', '较难', '困难']
        };
    },
    props: ['examId'],
    watch: {
        examId: {
            handler: function (val, oldVal) {
                this.tkStatistics()
            },
            immediate: true
        }
    },
    created() {
    },
    methods: {
        async tkStatistics() {
            const result = await this.Api.databaseManage.getPaper({ id: this.examId });

            this.conStatVos = result.data.paperAnalyses['一致性分析'];
            this.diffStatVos = result.data.paperAnalyses['难度分析'];
            this.knoStatVos = result.data.paperAnalyses['知识点分析'];
            this.queLevelStatVos = result.data.paperAnalyses['等级分析'];
            this.queStatVos = result.data.paperAnalyses['题型题量分析'];
        },

    }
};
</script>

<style scoped lang="scss">
.PaperAnalysis {
    width: 100%;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .table {
        width: 45%;
        margin-right: 2%;

        p {
            color: #3bafda;
        }
    }

}

::v-deep .el-table__cell {
    text-align: center;
}
</style>