<template>
    <div class="container">
        <ul class="list">
            <li v-for="(item, index) in examPaper" :key="index">
                <div class="top">
                    <div class="left">
                        <span class="name">{{ item.name }}</span>
                    </div>

                </div>
                <div class="top">
                    <div class="left">
                        <i class="iconfont icon-ruanjian-"></i>
                        <span>组卷时间:{{ item.createDttm }}</span>
                    </div>
                    <div class="right">
                        <a href="javascript:;" @click="analyse(item)">试卷分析</a>
                        <a href="javascript:;" @click="edit(item)">编辑</a>
                        <a href="javascript:;" @click="del(item)">删除</a>
                    </div>
                </div>
            </li>
        </ul>
        <!-- <div class="pagination" v-if="total > 0">
            <el-pagination background layout="prev, pager, next" :total="total" @size-change="sizeChange()"
                @current-change="currentChange" :current-page="current" :page-size="pageSize">
            </el-pagination>
        </div> -->
        <el-dialog title="试卷分析" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false">
            <div class="paper-analyse">
                <paper-analysis :examId="currentId"></paper-analysis>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import PaperAnalysis from './PaperAnalysis.vue';
export default {
    name: 'VolumesRecords',
    data() {
        return {
            examPaper: [],
            total: 0,
            current: 1,
            pageSize: 5,
            dialogVisible: false,
            currentId:null
        };
    },
    components:{PaperAnalysis},
    created() {
        this.getExamPaper();
    },
    methods: {
        async getExamPaper() {
            // todo 考试试卷
            const result = await this.Api.exammanage.getPapers({});
            this.examPaper = result.data;
            this.total = result.total;
        },
        async analyse(item) {
            this.currentId = item.id;
            this.dialogVisible = true;
            return
            const result = await this.Api.databaseManage.getPaper({ id: item.id });
    
        },
        edit(item) {
            // 1790561833981669378
            this.$router.push(`/admin/eidt-question/${item.id}`);
        },

        del(item) {
            this.$confirm('此操作将永久删除该试卷, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                let result = await this.Api.databaseManage.delPaper({ id: item.id })
                if (result.code != 200) {
                    this.$message.error(result.message);
                    return;
                }
                this.$message.success('删除成功');
                // this.current = 1;
                this.getExamPaper();

            }).catch(() => { }); // 取消不做任何操作  
        },
        sizeChange() { },
        currentChange(page) {
            this.current = page;
            this.getExamPaper();
        },

    }
};
</script>

<style scoped lang="scss">
.container {
    width: 100%;
    overflow: hidden;

    .list {
        margin: 0 20px;
        overflow: hidden;
        padding: 0;

        li {
            height: 100px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #d8d8d8;
            margin-bottom: 20px;
            list-style: none;
            box-sizing: border-box;

            .top,
            .bottom {
                display: flex;
                align-items: center;
                height: 50%;
                margin: 0 20px;
            }

            .top {
                justify-content: space-between;

                .left {
                    width: 70%;

                    .type {
                        display: inline-block;
                        width: 80px;
                        height: 28px;
                        line-height: 28px;
                        border-radius: 5px;
                        background: #f59a23;
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                        margin-right: 20px;
                    }
                }

                .right {
                    width: 20%;
                    display: flex;
                    justify-content: center;

                    a {
                        color: #3bafda;
                        margin-right: 30px;
                    }
                }
            }

            .bottom {
                span {
                    font-size: 14px;
                    margin-left: 3px;
                }
            }
        }
    }

    .pagination {
        text-align: center;
    }
}
.paper-analyse{
    max-height: 550px;
    overflow-y: auto;
    // width: 100%;
}
</style>